import React, {Suspense} from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as ROUTES from "../constants/Routes"
import "../css/Contact.css";
import "../css/About.css";

const Landing = React.lazy(() => import("./Landing"));
const Contact = React.lazy(() => import("./Contact"));
const About = React.lazy(() => import("./About"));
const Services = React.lazy(() => import("./Services"));
const Nav = React.lazy(() => import("./Nav"));
const Footer = React.lazy(() => import("./Footer"));




function App() {
  return (
    <Router>
      <Nav />
      <Routes>
        {/* <Route path="/" element={<Landing />} /> */}
        <Route exact path={ROUTES.LANDING} element={
          <Suspense fallback={<div>Loading</div>}> <Landing /> </Suspense>} />
        <Route exact path={ROUTES.ABOUT} element={
          <Suspense fallback={<div>Loading</div>}> <About /> </Suspense>} />
        <Route exact path={ROUTES.SERVICES} element={
          <Suspense fallback={<div>Loading</div>}> <Services /> </Suspense>} />
        <Route exact path={ROUTES.CONTACT} element={
          <Suspense fallback={<div>Loading</div>}> <Contact /> </Suspense>} />
      </Routes>
    </Router>
  );
}

export default App;
